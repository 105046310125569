import React, { useState } from 'react';
import Header from './components/Header';
import DescBlock from './components/DescBlock';
import AudioDemo from './components/AudioDemo';
import Footer from './components/Footer';

function App() {
  const defaultState = [
    {
      title: 'Storyteller-Engaged',
      fileName: 'AndrewHamel-storyteller-engaged',
    },
    {
      title: 'Deep-Warm',
      fileName: 'AndrewHamel-deep-warm',
    },
    {
      title: 'Gentle-Rhythmic',
      fileName: 'AndrewHamel-gentle-rhythmic',
    },
    {
      title: 'Assured-Caring',
      fileName: 'AndrewHamel-assured-caring',
    },
    {
      title: 'Texas-Chill',
      fileName: 'AndrewHamel-texas-chill',
    },
  ];

  // eslint-disable-next-line
  const [demos, _A029348598028345] = useState(defaultState);
  const titleLine = 'Andrew Hamel voice actor';

  return (
    <div id='container'>
      <Header />
      <div className='content'>
        <div className='name-tag'>
          <img
            title={`${titleLine} - Houston, Texas USA`}
            src='/img/name-tag.gif'
          />
        </div>
        <DescBlock
          type='h2'
          text='Donald Sutherland with a Texas twist, Warm-Rasp, Deep, Rich, Laid-Back'
        />
        <DescBlock
          type='h2'
          text='Source Connect Pro, Dedicated ISO Booth, Sennheiser 416 Mic, SSL Console, Steinberg DAW, Digital Phone Patch'
        />
        {demos.map((demo, idx) => (
          <AudioDemo key={`demo-${idx}`} data={demo} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';

const Header = () => {
  return (
    <header>
      <div className='email'>
        <a href='mailto:inquire@andrewhamelvoiceactor.com'>
          inquire@andrewhamelvoiceactor.com
        </a>
      </div>
      <div className='phone'>
        <a href='tel:+2817958442'>(281) 795-8442</a>
      </div>
    </header>
  );
};

export default Header;

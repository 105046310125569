import React from 'react';

const Footer = () => {
  const scrollToTop = (evt) => {
    evt.preventDefault();
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      Copyright &copy; 2022 Andrew Hamel voice actor. All Rights Reserved.
      &nbsp;&nbsp;
      <span className='to-top' onClick={(evt) => scrollToTop(evt)}>
        &#8593;
      </span>
    </footer>
  );
};

export default Footer;

import React from 'react';

const AudioDemo = ({ data }) => {
  const { fileName, title } = data;
  return (
    <div className='demo'>
      <h3>{title}</h3>
      <audio controls>
        <source src={`./audio/${fileName}.mp3`} type='audio/mpeg' />
        {/*<source src={`./audio/${fileName}.wav`} type='audio/wav' />*/}
        Audio tag is not supported in this browser.
      </audio>
    </div>
  );
};

export default AudioDemo;

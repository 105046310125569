import React from 'react';

const DescBlock = ({ type, text }) => {
  switch (type) {
    case 'h1':
      return <h1>{text}</h1>;
    case 'h2':
      return (
        <h2>
          <div>{text}</div>
        </h2>
      );
    default:
      return <div>{text}</div>;
  }
};

export default DescBlock;
